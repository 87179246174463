import React from 'react';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import {
  Box,
  Button,
} from '@material-ui/core';

import FormLabel from '@material-ui/core/FormLabel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import NotificationAlert from './NotificationAlert';
import DropDownSelect from '../../components/DropDownSelect';
import LanguageSelect from '../../components/LanguageSelect';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import {
  ALL_LANGUAGE_CODES, ALERT_OPTIONS, NOTIFICATION_ALERTS, TEMPLATE_TYPE
} from '../../constants/NotificationAlerts';

import { ONESIGNAL_API, API_KEY, APP_ID } from '../../constants/api';

import css from './style.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class NotificationScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // audienceType - keep it same as it is: https://documentation.onesignal.com/docs/segmentation
      audienceType: 'Test Users', // ['All', 'Test Users', ]
      enTitle: '',
      enMessage: '',
      frTitle: '',
      frMessage: '',
      nlTitle: '',
      nlMessage: '',
      selectedLang: ALL_LANGUAGE_CODES[0],
      tempType: 'freeText', // default
      alertType: '0', // default
      alertLang: 'en', // default
      selectedTempData: {},
      confirmDialog: false,
      isSending: false,
      snackOpen: false,
      transition: undefined,
      alertSeverity: 'info'
    };
  }

  isTitleEmpty = () => {
    const { enTitle, frTitle, nlTitle } = this.state;
    return (!enTitle && !frTitle && !nlTitle);
  }

  isMessageEmpty = () => {
    const { enMessage, frMessage, nlMessage } = this.state;
    return (!enMessage && !frMessage && !nlMessage);
  }

  handleDialogClick = (val) => {
    this.setState({ confirmDialog: false });
    if (val) {
      this.onSendNotification();
    }
  }

  onSendMessageClick = () => {
    const { audienceType } = this.state;
    if (audienceType === 'All') {
      this.setState({ confirmDialog: true });
    } else {
      this.onSendNotification();
    }
  }

  onSendNotification = () => {
    const {
      audienceType,
      selectedTempData,
      enTitle,
      enMessage,
      frTitle,
      frMessage,
      nlTitle,
      nlMessage,
      tempType,
    } = this.state;

    const isTemplAlertData = tempType === 'fixedTemplate' ? Object.keys(selectedTempData).length : null;

    if ((isTemplAlertData === null) && (this.isTitleEmpty() || this.isMessageEmpty())) {
      this.handleSnackClick('Please enter title and message !', 'error');
      return;
    }

    const header = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${API_KEY}`
    };

    let HEADINGS = {};
    let CONTENT = {};

    let ALERT_PAYLOAD = {};
    if (isTemplAlertData) {
      ALERT_PAYLOAD = this.createTempJson(selectedTempData);
      HEADINGS = ALERT_PAYLOAD.headingObj;
      CONTENT = ALERT_PAYLOAD.contentsObj;
    } else {
      HEADINGS = { en: enTitle, fr: frTitle, nl: nlTitle };
      CONTENT = { en: enMessage, fr: frMessage, nl: nlMessage };
    }

    const payload = {
      app_id: APP_ID,
      included_segments: [audienceType],
      headings: HEADINGS,
      contents: CONTENT,
    };

    this.setState({ isSending: true });
    fetch(ONESIGNAL_API, {
      method: 'POST',
      headers: header,
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((data) => {
        const err = data.errors && data.errors[0];
        if (data.errors) {
          this.setState({ isSending: false });
          this.handleSnackClick(`Something went wrong : ${err}`, 'error');
        } else if (data.recipients) {
          this.handleSnackClick('Message Delivered !', 'success');
          this.setState({
            enTitle: '',
            frTitle: '',
            nlTitle: '',
            enMessage: '',
            frMessage: '',
            nlMessage: '',
            isSending: false
          });
        }
      });
  }

  setAudienceType = (event) => {
    this.setState({ audienceType: event.target.value });
  }

  onChangeTitle = (event) => {
    const { selectedLang } = this.state;
    const langTitle = `${selectedLang.value}Title`;

    this.setState({ [langTitle]: event.target.value });

    // this.setState({ title: event.target.value });
  }

  onChangeMessage = (event) => {
    const { selectedLang } = this.state;
    const langTitle = `${selectedLang.value}Message`;

    this.setState({ [langTitle]: event.target.value });
    // this.setState({ message: event.target.value });
  }

  /* --- Snackbar click handle --- */
  handleSnackClick = (snackbarMsg, alertSeverity) => {
    this.setState({ snackOpen: true, snackbarMsg, alertSeverity });
  };

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
  };
  /* --- Snackbar click handle --- */

  showSnackbar = () => {
    const { snackOpen, snackbarMsg, alertSeverity } = this.state;

    const vertical = 'top';
    const horizontal = 'center';
    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={snackOpen}
        autoHideDuration={3000}
        onClose={this.handleSnackClose}
        message="Message Delivered"
        key={vertical + horizontal}
      >
        <Alert onClose={this.handleSnackClose} severity={alertSeverity}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    );
  }

  getUserType() {
    return (
      <div className="block">
        <div className="block-header">Audience</div>
        <div className="user-type-btns" onChange={this.setAudienceType}>
          <label>
            <input type="radio" value="Test Users" name="audienceType" defaultChecked />
            {' '}
            Send to Test Users
          </label>
          <label>
            <input type="radio" value="All" name="audienceType" />
            {' '}
            Send to Subscribed Users
          </label>
        </div>

      </div>
    );
  }

  setTemplateData = () => {
    const { alertType } = this.state;

    const alertData = this.getSelectedAlertData(alertType) || {};
    this.setState({ selectedTempData: alertData });
  }

  onTemplateTypeChange = (val) => {
    this.setState({ tempType: val }, () => {
      this.setTemplateData();
    });
  }

  onAlertChange = (val) => {
    this.setState({ alertType: val, title: '', message: '' }, () => {
      this.setTemplateData();
    });
  }

  onAlertLandChange = (val) => {
    this.setState({ alertLang: val }, () => {
      this.setTemplateData();
    });
  }

  onLangChange = (val) => {
    this.setState({ selectedLang: val });
  }

  showCustomNotificationForm = () => {
    const { selectedLang } = this.state;

    const langTitle = this.state[`${selectedLang.value}Title`];
    const langMessage = this.state[`${selectedLang.value}Message`];

    return (
      <div className="margin-left-20">
        <div className="form-headerss">
          {/* <p>Enter notification title and message:</p> */}
          {/* <DropDownSelect onAlertChange={this.onAlertChange} /> */}
        </div>
        <div className="input-lable">
          <TextField
            style={{ width: '50%' }}
            id="outlined-title-input"
            className="input-style"
            label={`Title (${selectedLang.label})`}
            variant="outlined"
            value={langTitle}
            onChange={this.onChangeTitle}
          />
        </div>

        <div className="input-lable">
          <TextField
            style={{ width: '50%' }}
            id="outlined-multiline-static"
            multiline
            rows={4}
            label={`Message (${selectedLang.label})`}
            variant="outlined"
            value={langMessage}
            onChange={this.onChangeMessage}
          />
        </div>
      </div>
    );
  }

  getSelectedAlertData = (alertType) => {
    const data = NOTIFICATION_ALERTS[alertType];
    return data;
  }

  getTemplateSelect = () => {
    const { tempType } = this.state;
    return (
      <div className="drop-down-container">
        <p className="label">Please choose the option '"Free Text"' or '"Pre-defined Template"'</p>
        <DropDownSelect
          label="Template"
          defaultVal={tempType}
          options={TEMPLATE_TYPE}
          onSelect={this.onTemplateTypeChange}
        />
      </div>
    );
  }

  getAlertTypeSelect = () => {
    const { alertType } = this.state;
    return (
      <div className="drop-down-container">
        <p className="label">Please choose Alert Type</p>
        <DropDownSelect
          label="Type"
          defaultVal={alertType}
          options={ALERT_OPTIONS}
          onSelect={this.onAlertChange}
        />
      </div>
    );
  }

  getLanguageSelect = () => {
    const { alertLang } = this.state;
    return (
      <div className="drop-down-container">
        <p className="label">Select alert Language :</p>
        <DropDownSelect
          label="Language"
          defaultVal={alertLang}
          options={ALL_LANGUAGE_CODES}
          onSelect={this.onLangChange}
        />
      </div>
    );
  }

  showLanguageBlock = () => {
    return (
      <div className="lang-select-block">
        <FormLabel>Language</FormLabel>
        <LanguageSelect data={ALL_LANGUAGE_CODES} onClick={this.onLangChange} />
      </div>
    );
  }

  showFixedTemplateBlock = () => {
    const { alertType, selectedLang } = this.state;
    const alertData = this.getSelectedAlertData(alertType) || {};
    return (
      <div>
        {/* this.getAlertTypeSelect() */}
        <NotificationAlert data={alertData} selectedLang={selectedLang.value} />
      </div>
    );
  }

  // create json to send notificaiton in multiple lang
  /*
    "headings": {"en": "English Message", "fr": "msg in french", "nl": "msg in dutch"}
    "contents": {"en": "English Message", "fr": "msg in french", "nl": "msg in dutch"}
  */
  createTempJson = (tempData) => {
    const headingObj = {};
    const contentsObj = {};
    for (const key in tempData) {
      if (tempData.hasOwnProperty(key)) {
        headingObj[key] = tempData[key].heading;
        contentsObj[key] = tempData[key].subheading;
      }
    }
    return { headingObj, contentsObj };
  }

  getSendNotificationTemplate() {
    const { tempType, isSending } = this.state;

    const showFixedTemplate = (tempType === 'freeText');

    const sendBtnText = isSending ? 'Sending...' : 'Send Message';

    return (
      <div className="block">
        <div className="block-header">Message</div>
        {this.getTemplateSelect()}
        <div className="form-header margin-btm-20">
          {!showFixedTemplate && this.getAlertTypeSelect()}
          {/* this.getLanguageSelect() */}
        </div>
        <Divider className="divider" variant="middle" />
        {this.showLanguageBlock()}
        <div className="form-content">
          {tempType === 'freeText'
            ? this.showCustomNotificationForm()
            : this.showFixedTemplateBlock()}
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.onSendMessageClick()}
            >
              {sendBtnText}
            </Button>
          </Box>
        </div>
      </div>
    );
  }

  render() {
    const { confirmDialog } = this.state;

    return (
      <div className="noti-template">
        <div className="noti-header">Compose Notification</div>
        {this.getUserType()}
        {this.getSendNotificationTemplate()}
        <ConfirmationDialog open={confirmDialog} handleClose={this.handleDialogClick} />
        {this.showSnackbar()}
      </div>
    );
  }
}
