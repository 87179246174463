/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:b02e22c7-becc-4555-b726-53fea2d10f9b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_5NjPzHorg",
    "aws_user_pools_web_client_id": "2h0cm2uh2e3f95kigrt09ch73",
    "oauth": {}
};


export default awsmobile;
