export const TEMPLATE_TYPE = [
  { label: 'Free Text', value: 'freeText' },
  { label: 'Fixed Template', value: 'fixedTemplate' },
];

export const ALL_LANGUAGE_CODES = [
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'Dutch', value: 'nl' }
];

export const ALERT_OPTIONS = [
  // { label: 'Free Text', value: 'default' },
  { label: 'ozone_heat0', value: '0' },
  { label: 'ozone_heat1', value: '1' },
  { label: 'ozone_heat2', value: '2' },
  { label: 'ozone0', value: '3' },
  { label: 'ozone1', value: '4' },
  { label: 'ozone2', value: '5' },
  { label: 'brussels0', value: '6' },
  { label: 'brussels1', value: '7' },
  { label: 'brussels2', value: '8' },
  { label: 'brussels3', value: '9' },
  { label: 'brussels4', value: '10' },
];

export const NOTIFICATION_ALERTS = [
  {
    en: {
      subheading: 'End of the alert',
      heading: 'High heat and ozone peak plan'
    },
    fr: {
      subheading: 'Fin de l’alerte',
      heading: 'Plan forte chaleur et pics d’ozone'
    },
    nl: {
      subheading: 'Einde alarmfase',
      heading: 'Hitte- en Ozonplan'
    }
  },
  {
    en: {
      subheading: 'Warning phase. Find out about the precautionary measures https://qualitedelair.brussels/ozone_heat1',
      heading: 'High heat and ozone peak plan'
    },
    fr: {
      subheading: 'Phase d’avertissement. Découvrez les mesures de précautions https://qualitedelair.brussels/ozone_heat1',
      heading: 'Plan forte chaleur et pics d’ozone'
    },
    nl: {
      subheading: 'Waarschuwingsfase. Ontdek de voorzorgsmaatregelen https://luchtkwaliteit.brussels/ozone_heat1',
      heading: 'Hitte- en Ozonplan'
    }
  },
  {
    en: {
      subheading: 'Alert phase. Find out about the precautionary measures https://qualitedelair.brussels/ozone_heat2',
      heading: 'High heat and ozone peak plan'
    },
    fr: {
      subheading: 'Phase d’alerte. Découvrez les mesures de précautions https://qualitedelair.brussels/ozone_heat2',
      heading: 'Plan forte chaleur et pics d’ozone'
    },
    nl: {
      subheading: 'Alarmfase. Ontdek de voorzorgsmaatregelen https://luchtkwaliteit.brussels/ozone_heat2',
      heading: 'Hitte- en Ozonplan'
    }
  },
  {
    en: {
      subheading: 'The situation is restored',
      heading: 'Ozone alert over'
    },
    fr: {
      subheading: 'La situation est rétablie',
      heading: 'Alerte ozone terminée'
    },
    nl: {
      subheading: 'De normale toestand is hersteld',
      heading: 'Ozonalarm afgelopen'
    }
  },
  {
    en: {
      subheading: 'Risk of exceeding the European information threshold. Find out about the precautionary measures https://qualitedelair.brussels/ozone1',
      heading: 'Ozone alert '
    },
    fr: {
      subheading: 'Risque de dépassement du seuil européen d’information. Découvrez les mesures de précautions https://qualitedelair.brussels/ozone1',
      heading: 'Alerte ozone '
    },
    nl: {
      subheading: 'Risico op overschrijding van de Europese informatiedrempel. Ontdek de voorzorgsmaatregelen https://luchtkwaliteit.brussels/ozone1',
      heading: 'Ozonalarm'
    }
  },
  {
    en: {
      subheading: 'Risk of exceeding the European alert threshold. Find out about the precautionary measures. https://qualitedelair.brussels/ozone2',
      heading: 'Ozone alert'
    },
    fr: {
      subheading: 'Risque de dépassement du seuil européen d’alerte. Découvrez les mesures de précautions https://qualitedelair.brussels/ozone2',
      heading: 'Alerte ozone'
    },
    nl: {
      subheading: 'Risico op overschrijding van de Europese waarschuwingsdrempel. Ontdek de voorzorgsmaatregelen https://luchtkwaliteit.brussels/ozone2',
      heading: 'Ozonalarm'
    }
  },
  {
    en: {
      subheading: 'End of the alert',
      heading: 'Fine particulate matter alert'
    },
    fr: {
      subheading: 'Fin de l’alerte',
      heading: 'Alerte Particules fines'
    },
    nl: {
      subheading: 'Einde alarmfase',
      heading: 'Fijnstofalarm'
    }
  },
  {
    en: {
      subheading: 'Information and awareness threshold. Find out about the precautionary measures https://qualitedelair.brussels/brussels1',
      heading: 'Fine particulate matter alert'
    },
    fr: {
      subheading: 'Seuil d’information et de sensibilisation. Découvrez les mesures de précautions https://qualitedelair.brussels/brussels1',
      heading: 'Alerte Particules fines'
    },
    nl: {
      subheading: 'Informatie- en sensibiliseringsdrempel. Ontdek de voorzorgsmaatregelen https://luchtkwaliteit.brussels/brussels1',
      heading: 'Fijnstofalarm'
    }
  },
  {
    en: {
      subheading: 'Information and intervention threshold. Find out about the precautionary measures https://qualitedelair.brussels/brussels2',
      heading: 'Fine particulate matter alert'
    },
    fr: {
      subheading: 'Seuil d’information et d’intervention. Découvrez les mesures de précautions https://qualitedelair.brussels/brussels2',
      heading: 'Alerte Particules fines'
    },
    nl: {
      subheading: 'Informatie- en interventiedrempel. Ontdek de voorzorgsmaatregelen https://luchtkwaliteit.brussels/brussels2',
      heading: 'Fijnstofalarm'
    }
  },
  {
    en: {
      subheading: 'Action level 1. Find out about the precautionary measures https://qualitedelair.brussels/brussels3',
      heading: 'Fine particulate matter alert'
    },
    fr: {
      subheading: 'Seuil d’intervention 1. Découvrez les mesures de précautions https://qualitedelair.brussels/brussels3',
      heading: 'Alerte Particules fines'
    },
    nl: {
      subheading: 'Interventiedrempel 1. Ontdek de voorzorgsmaatregelen https://luchtkwaliteit.brussels/brussels3',
      heading: 'Fijnstofalarm'
    }
  },
  {
    en: {
      subheading: 'Action level 2. Find out about the precautionary measures  https://qualitedelair.brussels/brussels4',
      heading: 'Fine particulate matter alert '
    },
    fr: {
      subheading: 'Seuil d’intervention 2. Découvrez les mesures de précautions https://qualitedelair.brussels/brussels4',
      heading: 'Alerte Particules fines '
    },
    nl: {
      subheading: 'Interventiedrempel 2. Ontdek de voorzorgsmaatregelen https://luchtkwaliteit.brussels/brussels4',
      heading: 'Fijnstofalarm'
    }
  },
];
