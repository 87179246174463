import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  paper: {
    maxWidth: 500,
    // margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

function createMarkup(htmlTxt) {
  return { __html: htmlTxt };
}

function NotificationBlock(title, selectedLang, text) {
  const classes = useStyles();
  return (
    <div className="margin-btm-20">
      <Typography variant="subtitle1" gutterBottom>
        {title}
      </Typography>
      <Paper className={classes.paper}>
        <Grid container wrap="nowrap" spacing={3}>
          <Grid item>
            <Avatar>{selectedLang.toUpperCase()}</Avatar>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography><div dangerouslySetInnerHTML={createMarkup(text)} /></Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default function NotificationAlert(props) {
  const classes = useStyles();
  const { selectedLang, data } = props;

  const msgData = data && (data[selectedLang] || {});

  return (
    <div className={classes.root}>
      {NotificationBlock('Title', selectedLang, msgData.heading)}
      {NotificationBlock('Message', selectedLang, msgData.subheading)}
    </div>
  );
}
