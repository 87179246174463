// Change point to production while deploying
export const BASE_URL = 'https://stg.socialnext.eu'; // 'https://brusselsair.symantra.be'

export const APP_ID = 'cef152ea-cd7d-4ebe-b260-d766f0bad10c'
export const API_KEY = 'NzFlMTFhMzQtNTI4YS00MDZmLTgzY2QtZDM1OTVhNmI1MTYz'



export const ONESIGNAL_API = 'https://onesignal.com/api/v1/notifications'

export const NOTIFICATION_API = `${ONESIGNAL_API}?app_id=${APP_ID}`;

export const DEVICE_LIST_API = `https://onesignal.com/api/v1/players?app_id=${APP_ID}&test_users=true`

export const deleteDevice = (player_id) => `https://onesignal.com/api/v1/players/${player_id}?app_id=${APP_ID}`

export const HEADER = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Authorization': 'Basic ' + API_KEY
}
