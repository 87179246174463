import React from 'react';
import Loader from 'react-loader-spinner';
import './style.css';
import { HEADER, NOTIFICATION_API } from '../../constants/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import 'moment/locale/nl-be';
import * as momentTZ from 'moment-timezone';

momentTZ.locale('nl-be');
momentTZ.tz.setDefault('Europe/Brussels');
export default class History extends React.Component {
  constructor() {
    super();
    this.state = {
      history: [],
      loader: true,
    };
  }

  componentDidMount() {
    fetch(NOTIFICATION_API, {
      method: 'GET',
      headers: HEADER
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          history: data.notifications,
          loader: false
        });
      });
  }

  showDate(utcSeconds) {
    let date = new Date(utcSeconds*1000);
    return (<div style={{ paddingRight: 5, paddingLeft: 5}}>{momentTZ(date).format('DD/MM/YYYY hh:mm:ss A')}</div>);
  }

  renderNoData() {
    if (this.state.loader) {
      return (
        <Card className="load-view">
          <PerfectScrollbar>
            <Loader
              type="Oval"
              color="#00BFFF"
              height={30}
              width={30}
            />
          </PerfectScrollbar>
        </Card>
      );
    }
    return (
      <div className="load-view">
        <div>No Data Found</div>
      </div>
    );
  }

  render() {
    if (this.state.history.length === 0) {
      return this.renderNoData();
    }
    console.log(this.state.history);
    return (
      <Card>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <h1 style={{color: '#4f585f', textAlign: 'center', paddingBottom: 20, paddingTop: 20 }}>
              History
            </h1>
            <Table>
              <TableHead>
                <TableRow className="listheader">
                  <TableCell className='item' style={{textAlign: 'center'}}>
                    TITLE
                  </TableCell>
                  <TableCell className='item' style={{textAlign: 'center'}}>
                    MESSAGE
                  </TableCell>
                  <TableCell className='item' style={{textAlign: 'center'}}>
                    SENDING TIME
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.history.map((notification) => (
                  <TableRow
                    hover
                    key={notification.id}
                    className='listData'
                  >
                    <TableCell style={{ textAlign: 'center' }}>
                      {notification.headings.en}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {notification.contents.en}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', width: 210}}>
                      {this.showDate(notification.completed_at)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    );
  }
}
