import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: 'block'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function DropDownSelects(props) {
  const classes = useStyles();
  const { options, defaultVal, label } = props;
  const [notificationTemp, setNotificationTemp] = React.useState(defaultVal);
  // console.log('notificationTemp : ', notificationTemp);
  // console.log('options : ', options);

  const handleChange = (event) => {
    setNotificationTemp(event.target.value);
    props.onSelect(event.target.value);
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">{label || ''}</InputLabel>
        <Select
          label={label || ''}
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={notificationTemp}
          onChange={handleChange}
        >
          {options && options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

DropDownSelects.propTypes = {
  options: PropTypes.array,
  defaultVal: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func,
};
