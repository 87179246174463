import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function LanguageSelect(props) {
  const classes = useStyles();
  const { data } = props;
  const [selectedBtn, setBtn] = React.useState('en');

  const handleClick = (d) => {
    setBtn(d.value);
    props.onClick(d);
  };

  return (
    <div className={classes.root}>
      {
        data.map((d) => (
          <Button
            style={{ height: '20px', color: '#1f8feb', backgroundColor: `${(d.value === selectedBtn) ? '#e5f2fc' : '#fff'}` }}
            variant="contained"
            color="primary"
            onClick={() => handleClick(d)}
            key={`btn-lang-${d.value}`}
          >
            {d.label}
          </Button>
        ))
      }
    </div>
  );
}
