import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  Box,
  Card,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import css from './style.css';
import { encode } from 'base-64';
import { makeStyles } from "@material-ui/core/styles";

import Button from '@material-ui/core/Button';
import { OPTIONS_DAY0, OPTIONS_DAY0_O3 } from '../../constants/days';
import { BASE_URL } from '../../constants/api';

export default class ShowAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      optionsDay0: OPTIONS_DAY0,
      optionsDay03: OPTIONS_DAY0_O3,
      day0Value: 'default',
      day03Value: 'default',
      showAlert: false,
      alertMesssage: '',
      severity: '',
    };
  }

  componentDidMount() {
    const url = `${BASE_URL}/air/data.txt`;
    fetch(url, { method: 'GET' })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        this.setState({
          content: data
        });
      });
  }

  handleChangeDay0 = (event) => {
    this.setState({ day0Value: event.target.value });
  };

   handleChangeDay03 = (event) => {
     this.setState({ day03Value: event.target.value });
   };

   saveFile() {
     const date = `${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`;
     if (this.state.day0Value !== 'default' && this.state.day03Value !== 'default') {
       const day0Value = `<day0>${this.state.day0Value}</day0>`;
       const day03Value = `<day0_o3>${this.state.day03Value}</day0_o3>`;
       const dayValue = `<date>${date}</date>`;
       const newContent = this.state.content.replace(/<day0>.*<\/day0>/, day0Value);
       const newContent1 = newContent.replace(/<day0_o3>.*<\/day0_o3>/, day03Value);
       const content = newContent1.replace(/<date>.*<\/date>/, dayValue);
       this.setState({ showAlert: false });
       this.setState({ content });
       // var headers = new Headers();
       // headers.set('Authorization', 'Basic ' + encode("user1" + ":" + "abcd1234"));
       const url = `${BASE_URL}/bxl_air/data?day0=${this.state.day0Value}&day0_o3=${this.state.day03Value}&date=${date}`;
       fetch(url, { method: 'POST', mode: 'no-cors' })
         .then((r) => {
           console.log(r);
           this.setState({ showAlert: true, alertMesssage: 'The alert values have been updated successfully.', severity: 'success' });
         })
     } else {
       this.setState({ showAlert: true, alertMesssage: 'Please Select values', severity: 'error' });
     }
   }

   closeAlert() {
     this.setState({
       showAlert: false
     });
   }

   render() {
     const {
       optionsDay0, optionsDay03, day0Value, day03Value, content, showAlert, alertMesssage, severity
     } = this.state;
     return (
       <Card>
         <PerfectScrollbar>
           <Snackbar open={showAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center'} } autoHideDuration={6000} onClose={this.closeAlert.bind(this)}>
             <Alert onClose={this.closeAlert.bind(this)} severity={severity} elevation={6} variant="filled" >
               {alertMesssage}
             </Alert>
           </Snackbar>
           <Box>
             <h1 style={{ color: '#4f585f', textAlign: 'center', paddingTop: 20 }}>Modify Alert Value</h1>
             <div className="box">
               <div className="content-box">
               <div style={{backgroundColor: '#edf0f2', paddingRight: 20, paddingLeft: 20, paddingTop: 10, paddingBottom: 10, fontSize: 16}}>Alert.txt</div>
               <div style={{ width: 160, padding: 20,fontSize: 14, fontWeight: '400', color: '#1b3752'}}>{content}</div>
               </div>
               <div className="flexbox-container">
                  <div style={{ flex: 0.3 }}>
                    <div className="title">Change Day 0</div>
                    <FormControl variant="outlined" style={{ margin: 10, minWidth: 120, }}>
                      <InputLabel id="demo-simple-select-outlined-label">Day0</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={day0Value}
                        onChange={this.handleChangeDay0.bind(this)}
                        label="Day0"
                      >
                        {optionsDay0.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                 <div style={{flex: 0.3}}>
                   <div className="title">Change Day 0_o3</div>
                   <FormControl
                     variant="outlined"
                     style={{ margin: 10, minWidth: 120 }}
                   >
                     <InputLabel id="demo-simple-select-outlined-label">Day3</InputLabel>
                     <Select
                       labelId="demo-simple-select-outlined-label"
                       id="demo-simple-select-outlined"
                       value={day03Value}
                       onChange={this.handleChangeDay03.bind(this)}
                       label="Day0"
                     >
                       {optionsDay03.map((item) => (
                         <MenuItem key={item.value} value={item.value}>
                           {item.name}
                         </MenuItem>
                       ))}
                     </Select>
                   </FormControl>
                 </div>
               </div>
               <div style={{alignSelf: 'flex-end', flexDirection: 'column',textAlign: 'right' }}>
                 <button className='btn' onClick={() => { this.saveFile()}} style={{ margin: 20, alignSelf: 'flex-end',  }}>Update</button>
               </div>
             </div>
           </Box>
         </PerfectScrollbar>
       </Card>
     );
   }
}
