import React from "react";
import Loader from 'react-loader-spinner';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import css from "./style.css";
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {HEADER, DEVICE_LIST_API, APP_ID} from '../../constants/api';
import { DEVICE_DATA } from '../../constants/testDevicesData';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      loader: true
    };
  }

  componentDidMount() {
    // use local device data for test devices
    // this.getDevices();
    this.setState({
      devices: DEVICE_DATA.players,
      loader: false
    });
  }

  getDevices() {
    fetch(DEVICE_LIST_API, {
      method: 'GET',
      headers: HEADER
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          devices: data.players,
          loader: false
        });
      });
  }

  showDate(utcSeconds) {
    let date = new Date(utcSeconds*1000);
    return (<div>{moment(date).format('DD/MM/YYYY')}</div>);
  }


  renderNoData() {
    if(this.state.loader) {
      return (
        <div className="load-view">
          <Loader
            type="Oval"
            color="#00BFFF"
            height={30}
            width={30}
          />
        </div>
      );
    }
    return (
      <div className="load-view">
        <div>No Data Found</div>
      </div>
    );
  }

  render() {
    if (this.state.devices.length === 0) {
      return this.renderNoData();
    }
    return (
      <Card>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <h1 style={{color: '#4f585f', textAlign: 'center', paddingBottom: 20, paddingTop: 20 }}>
              List of Devices
            </h1>
            <Table>
              <TableHead>
                <TableRow className="listheader">
                  <TableCell className='item' style={{textAlign: 'center'}}>
                    DEVICE MODEL
                  </TableCell>
                  <TableCell className='item' style={{textAlign: 'center'}}>
                    DEVICE OS
                  </TableCell>
                  <TableCell className='item' style={{textAlign: 'center'}}>
                    SESSION
                  </TableCell>
                  <TableCell className='item' style={{textAlign: 'center'}}>
                    TOKEN
                  </TableCell>
                  <TableCell className='item' style={{textAlign: 'center'}}>
                    CREATED AT
                  </TableCell>
                  <TableCell className='item' style={{textAlign: 'center'}}>
                    ACTION
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.devices.map((device) => (
                  <TableRow
                    hover
                    key={device.id}
                    className='listData'
                  >
                    <TableCell style={{ textAlign: 'center' }}>
                    {device.device_model}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                     {device.device_os}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                     {device.session_count}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {device.id}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {this.showDate(device.created_at)}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      <button
                        className="delete-btn"
                        onClick={this.deleteTask.bind(this, device.id)}
                       >
                       Delete
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    );
  }

  deleteTask(player_id) {
    fetch(`https://onesignal.com/api/v1/players/${player_id}?app_id=${APP_ID}`,
      {
        method: 'DELETE',
        headers: HEADER
      })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if(data.success === true) {
          var newdevices = this.state.devices.filter(item => item.id !== player_id);
          this.setState({ devices: newdevices });
        }
      })
      .catch(err => {
        throw err;
    });
  }
}
