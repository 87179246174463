import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState, useEffect }  from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
// import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';

import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';
// import { Redirect } from "react-router";

// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

Amplify.configure(awsconfig);

const auth = 
{
    // "domain": "bxlair.auth.eu-west-1.amazoncognito.com",
    "domain": "bruair.symantra.be",
      "scope": [
            "phone",
            "email",
            "profile",
            "openid",
            "aws.cognito.signin.user.admin"
          ],
      // "redirectSignIn": "https://dev.d1htqx5v9dalli.amplifyapp.com",
      // "redirectSignOut": "https://dev.d1htqx5v9dalli.amplifyapp.com",
      // "redirectSignIn": "https://production.d25e08zbzneokf.amplifyapp.com",
      // "redirectSignOut": "https://production.d25e08zbzneokf.amplifyapp.com",
      "redirectSignIn": "https://brusselsair.symantra.be",
      "redirectSignOut": "https://brusselsair.symantra.be",
      "responseType": "token"
}

Auth.configure({ oauth: auth })

const App = () => {
  const [user, setUser] = useState(null);
  const routing = useRoutes(routes);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log('sign in', event, data)
          setUser({ user: data})
          break
        case 'signOut':
          console.log('sign out')
          setUser({ user: null })
          break
        default:
          console.log('Unhandled event: ' + event)
      }
    })
  });

  Auth.currentAuthenticatedUser().then(user => {
      console.log('currentAuthenticatedUser', user)
    //setState({user: user})
  }).catch(() => {
    console.log('Not signed in')
    Auth.federatedSignIn()
    // return <Redirect to={Auth.federatedSignIn()} />
  })

  if (user) {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    );
  } else {
    return <div />;
  }
};

export default App;
