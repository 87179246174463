export const DEVICE_DATA = {
  total_count: 8,
  offset: 0,
  limit: 300,
  players: [
    {
      id: 'bde99781-4c68-489f-a2a6-451749cbcc47',
      identifier: 'a73e3d7c0fcf9291af665bd2406052835709b26cf0e51d71ea3875b77f0bebb0',
      session_count: 52,
      language: 'en',
      timezone: 3600,
      game_version: '25',
      device_os: '14.1',
      device_type: 0,
      device_model: 'iPhone12,1',
      ad_id: '8B059388-8074-4474-BBCA-77CED8ECDAE8',
      tags: {
        App_Language: 'en',
        PN_Permission: '2',
      },
      last_active: 1607853628,
      playtime: 1133,
      amount_spent: 0,
      created_at: 1606726575,
      invalid_identifier: false,
      badge_count: 0,
      sdk: '021600',
      test_type: null,
      ip: null,
      external_user_id: null,
    },
    {
      id: 'a7f54829-572d-4607-956f-ebc49635bf32',
      identifier: '71f138563b86bf1043b4c1c5c625d9652ba1fc26932d9780d7ce59e617e0644f',
      session_count: 4,
      language: 'fr',
      timezone: 3600,
      game_version: '25',
      device_os: '13.7',
      device_type: 0,
      device_model: 'iPhone8,1',
      ad_id: '72F23173-E1C2-46D4-AFB9-B509949D4612',
      tags: {
        App_Language: 'fr',
        PN_Permission: '2',
      },
      last_active: 1606821138,
      playtime: 1680,
      amount_spent: 0,
      created_at: 1606752175,
      invalid_identifier: false,
      badge_count: 0,
      sdk: '021600',
      test_type: null,
      ip: null,
      external_user_id: null,
    },
    {
      id: 'e48d1365-8304-410c-829d-3de9de075c90',
      identifier: 'f2er9MCMqGM:APA91bGz2Q_ID-4rCFXteVk3zZqpDbKpPWXSVft-1ZwAnq79Rjp0giSzCBRPzBUCykC9vXbNpvC8PxrkGKxqzXrS6hN1QxpJwBqh3vH4yGUbOwnyLnYpfvpB0W8rxuho7dXFjs3amj-r',
      session_count: 8,
      language: 'fr',
      timezone: 3600,
      game_version: '25',
      device_os: '8.0.0',
      device_type: 1,
      device_model: 'SM-A520F',
      ad_id: '85a80580-f174-49d7-8925-31b95c3dce49',
      tags: {},
      last_active: 1607078306,
      playtime: 216,
      amount_spent: 0,
      created_at: 1607008507,
      invalid_identifier: false,
      badge_count: 0,
      sdk: '031506',
      test_type: null,
      ip: null,
      external_user_id: null,
    },
    {
      id: '01bd8a11-872a-44c0-a4bc-161d2080b7f8',
      identifier: 'ebjIchb1yww:APA91bFEmamM5e23ZJ9iQ1FbIDOo1Vf5EKkFFe6LVqXprmj-koXX19pV1PXbNrLZnk_W2F6JjeLz3A4852ETXtQLl08eTixVkE5_voe-Di3YuKNWZHcmTbIzV6AHSsJ5CyCSw2WIlvf_',
      session_count: 8,
      language: 'fr',
      timezone: 3600,
      game_version: '25',
      device_os: '10',
      device_type: 1,
      device_model: 'SM-G781B',
      ad_id: '0103fee9-df74-4ed8-9de5-038a38e46150',
      tags: {},
      last_active: 1607415213,
      playtime: 137,
      amount_spent: 0,
      created_at: 1607009298,
      invalid_identifier: false,
      badge_count: 0,
      sdk: '031506',
      test_type: null,
      ip: null,
      external_user_id: null,
    },
    {
      id: 'a2b5bdc6-ef09-4c5b-9715-258e39c9e1d0',
      identifier: '713e6da0db8214d1ee91dc4097b28c928a1cad29b6061efd4ca750090e8e06df',
      session_count: 3,
      language: 'fr',
      timezone: 3600,
      game_version: '25',
      device_os: '14.2',
      device_type: 0,
      device_model: 'iPhone9,3',
      ad_id: 'AE2B97B7-3849-4D80-9C71-843D997889DB',
      tags: {
        App_Language: 'fr',
        PN_Permission: '0',
      },
      last_active: 1607696025,
      playtime: 0,
      amount_spent: 0,
      created_at: 1607616818,
      invalid_identifier: false,
      badge_count: 0,
      sdk: '021600',
      test_type: null,
      ip: null,
      external_user_id: null,
    },
    {
      id: '4ed5350e-fa97-43fa-9d00-be3f837314a2',
      identifier: 'eJvjmtlNni4:APA91bFPyR1T6snvXbn_sZKyY0orPK5v5LkaJ7ruQAefwNtP3FiWxntUSAoQOWHk8L595_Yv6SsHhttwvKvWB2IokmJq0_44G-Ar-Ni5x4vLkGkJvv2ZJrzeNjBX8CIFRbhfvcSfBWCR',
      session_count: 3,
      language: 'fr',
      timezone: 3600,
      game_version: '25',
      device_os: '8.0.0',
      device_type: 1,
      device_model: 'SM-G930F',
      ad_id: '5c0fe7cf-2619-4d6a-93f5-6b4924b34459',
      tags: {},
      last_active: 1607703080,
      playtime: 307,
      amount_spent: 0,
      created_at: 1607672513,
      invalid_identifier: false,
      badge_count: 0,
      sdk: '031506',
      test_type: null,
      ip: null,
      external_user_id: null,
    },
    {
      id: '0660900a-ac5b-4615-b64c-642bdeb0ee6a',
      identifier: 'dCG0vFsCXBI:APA91bGjOe7t0vGBukXKxmwl7A89r4ZePTcooQPVYeHkl5lUdO6R-iUrOireKHbDrMxSky3mqL2-H7NS27tcDgZoViEP_A9VeCgQPFW8eApxxsEOFHzfuZ7W91B5eEmGoob3GDZS4bpx',
      session_count: 5,
      language: 'fr',
      timezone: 3600,
      game_version: '25',
      device_os: '5.1.1',
      device_type: 1,
      device_model: 'SM-J320F',
      ad_id: 'b857d4cf-5ce5-41bb-a933-78795dc6e8db',
      tags: {},
      last_active: 1607702369,
      playtime: 182,
      amount_spent: 0,
      created_at: 1607674115,
      invalid_identifier: false,
      badge_count: 0,
      sdk: '031506',
      test_type: null,
      ip: null,
      external_user_id: null,
    },
    {
      id: 'f122ac84-ce4b-43df-8d4a-0baa6b022c93',
      identifier: 'c9vfCQmcamI:APA91bGGtDlF6jacCoI5HG63fL-Q_5Ifp4eKqhf5UqEXwDWT8Bb7jfMFiQKZ2Vo0Us6PR5OszJ5NUfq1zxVDZ_o2MXoFEGNeFuiIEOPJvDHKi4kSCJi4oEL706pSnWHUtksg-jA9EvzW',
      session_count: 2,
      language: 'fr',
      timezone: 3600,
      game_version: '25',
      device_os: '8.0.0',
      device_type: 1,
      device_model: 'SM-G935F',
      ad_id: '6daff047-643a-4265-802f-061cf08712c2',
      tags: {},
      last_active: 1607940856,
      playtime: 0,
      amount_spent: 0,
      created_at: 1607940748,
      invalid_identifier: false,
      badge_count: 0,
      sdk: '031506',
      test_type: null,
      ip: null,
      external_user_id: null,
    },
  ],
};
