export const OPTIONS_DAY0 = [
  {
    name: 'Select…',
    value: 'default',
  },
  {
    name: 0,
    value: 0,
  },
  {
    name: 1,
    value: 1,
  },
  {
    name: 3,
    value: 3,
  },
  {
    name: 4,
    value: 4,
  },
  {
    name: 5,
    value: 5,
  },
];

export const OPTIONS_DAY0_O3 = [
  {
    name: 'Select…',
    value: 'default',
  },
  {
    name: 0,
    value: 0,
  },
  {
    name: 1,
    value: 1,
  },
];
