import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';
import { useTheme } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import Snackbar from '@material-ui/core/Snackbar';

import { VERIFICATION_CODE } from '../constants/globalSettings';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ConfirmationDialog(props) {
  const { open } = props;
  const [verificationCode, setCode] = React.useState('');

  /* --- Snackbar state--- */
  const vertical = 'top';
  const horizontal = 'center';
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  /* --- Snackbarstate  --- */

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    props.handleClose(false);
  };

  /* --- Snackbar click handle --- */
  const handleSnackClick = (Transition) => {
    setTransition(() => Transition);
    setSnackOpen(true);
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };
  /* --- Snackbar click handle --- */

  const reSetCode = () => {
    setCode('');
  };

  const onChange = (event) => {
    setCode(event.target.value);
  };

  const handleConfirmation = () => {
    if (VERIFICATION_CODE === verificationCode) {
      // Send message on right code
      props.handleClose(true);
    } else {
      // Show error message on wrong code
      handleSnackClick();
    }
    reSetCode();
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Confirm Before Sending</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure, you would like to send a message to all the real subscribers? If so, please enter the code.
          </DialogContentText>
          <TextField
            style={{ width: '50%' }}
            id="outlined-title-input"
            className="input-style"
            label="Code"
            variant="outlined"
            value={verificationCode}
            onChange={onChange}
          />
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackOpen}
            onClose={handleSnackClose}
            TransitionComponent={transition}
            message="I love snacks"
            key={vertical + horizontal}
          >
            <Alert onClose={handleSnackClose} severity="error">
              Wrong code entered. please try again
            </Alert>
          </Snackbar>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmation} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
